import React, { useEffect, useState } from 'react'
import { HeaderStyle } from './Header.style'
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('user')) {
        setLoggedIn(true)
    }

    let interval = setInterval(() => {
      if (sessionStorage.getItem('user')) {
        setLoggedIn(true)
        clearInterval(interval)
    }
    }, 500);
}, []);
useEffect(() => {
let interval = setInterval(() => {
    if (sessionStorage.getItem('user')) {
      setLoggedIn(true)
      clearInterval(interval)
  }
  }, 500);
}, [loggedIn]);
console.log(JSON.parse(sessionStorage.getItem('user')));
  return (
   loggedIn ? <HeaderStyle>
            <h1 onClick={() => {
              if (JSON.parse(sessionStorage.getItem('user')).cvr) {
                navigate(`/clientpage/${JSON.parse(sessionStorage.getItem('user')).id}`)
              }else{
                navigate('/dashboard')
              }
              }}>AgroAPV</h1>
            <p>{loggedIn ? "logget ind som: " + JSON.parse(sessionStorage.getItem('user'))?.firstname : ""} </p>
            <button onClick={() => {
              sessionStorage.removeItem('user')
              setLoggedIn(false)
              navigate('/')
              }}>log ud</button>
    </HeaderStyle> : null

  )
}

export default Header