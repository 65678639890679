import { styled } from "styled-components";

export const HeaderStyle = styled.header`
margin: 2vh 4vw;
background-color: #007297;
border-radius: 6px;
height: 5vh;
padding-top: 1vh;
padding-bottom: 1vh;

h1{
    text-align: center;
    font-weight: 500;
    color: white;
}

@media screen and (min-width: 768px) {
    margin: 1vh 1vw;
    padding: 1vh 0.5vw;
    height: 97vh;
    }
    
`