import { styled } from "styled-components";

export const LoginStyle = styled.section`
display: flex;
overflow: hidden;
height: 100vh;
.imageSection{
    background-color: white;
    height: 100vh;
    width: 100vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

footer{
    width: 100%;
    height: 4vh;
    margin-top: 5vh;
    border-top: solid 1px black;
    p{
        text-align: center;
        padding: 1.5vh 0;
        font-size: 15px;
        font-weight: 100;
        color: gray;
    }
}

@media screen and (max-width: 768px) {
display: flex;
justify-content: center;
    .imageSection{
        display: none;
}
}

`