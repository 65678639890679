import { styled } from "styled-components";


export const EditAPVStyle = styled.section`
margin-top :1vh ;
text-align: center;

#modalBtn{
    position: absolute;
    top: 20px;
    right: 30px;
    height: 5vh;
    width: 10vw;
    font-size: 30px;
    font-weight: bold;
    
}

.modal{
    position: fixed;
    left: 50px;
    right: 50px;
    background-color: white;
    border: solid black 1px;
    height: auto;
    height: 30vh;
    .modal-content{
        background-color: white;  
        height: fit-content;
        height: 30vh;

    }
}

#toTop{
position: fixed;
width: 10vw;
height: 6vh;
bottom: 20px;
right: 20px;
background-color: rgba(0,0,0,0);
border: none;
font-size: 25px;
}

button{
    display: block;
    text-align: center;
    width: 90%;
    margin-left: 4vw;
    border-radius: 6px;
    height: 6vh;
    font-size: 17px;
    border: solid black 1px;
    margin-bottom: 1vh;
}
.mobileForm{

    
    div{
        background-color: #007297;
        border-radius: 6px;
        padding-bottom: 2vh;
        padding-top: 2vh;
        margin-bottom: 2vh;
        p{
            margin-bottom: 2vh;
            font-size: 17px;
            font-weight: bold;
        }
    }
    
    h2{
        border-bottom: solid black;
        margin-bottom: 4vh;
        margin-top: 6vh;
    }
    
    
    input{
        width: 90%;
        height: 4vh;
        margin-bottom: 2vh;
        font-size: 17px;
    }
}

.desktopForm{
    width: 80vw;
    h2{
        font-weight: 400;
        font-size: 17px;
    }
.categoryDiv{
    display: grid;
    grid-template-columns: 60% 13% 13% 14%;
}

.headingArt{
        display: grid;
        grid-template-columns: 10% 50% 13% 13% 13%;
        border: solid 1px black;
        border-top: none;
        span{
            display: flex;
            justify-content: space-between;
                   p{
            height: 5vh;
        } 

        }
        input{
            width: 100%;
            height: 5vh;
            border: none;
            border-bottom: solid black 1px;
            font-size: 23px;
        }
}

.handleplanArt{
    display: grid;
    grid-template-columns: 30% 30% 20% 20%;
    border: solid 1px black;
    border-top: none;
    textarea{
        font-size: 20px;
    }
    div{
         
        border-right: solid black 1px; 
    }
    span{
        display: flex;
        justify-content: space-between;
               p{
        height: 5vh;
    } 

    }
    input{
        width: 100%;
        height: 4vh;
        margin-top: 4vh;
        width: 12vw;
        border: none;
        border-bottom: solid black 1px;
        font-size: 23px;
    }

    button{
        width: 11.5vw;
        height: 10vh;
        margin-top: 1vh;
    }
    .deadlineInputDiv{    
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
        label{
            padding-top: 4vh;
        width: 10vw;
    }
    input:first-of-type{  
        height: 2vh;
        margin-top: 4vh;
    }
    input:last-of-type{  
        height: 3vh;
        margin-top: 4vh;
        width: 10vw;
    }
}

}
.newQuestion{
    margin: 0;
    height: 5vh;
    width: 100%;
}
}

@media screen and (max-width: 768px) {
    margin: 0;
}

`