import React from 'react'
import { LoginStyle } from './Login.style'
import LoginForm from '../../Components/Login/LoginForm'
import image from "../../Assets/image.png"

function Login() {
  return (
    <LoginStyle>
<section>
               <LoginForm />
               <footer>
                <p>Copyright {new Date().getFullYear()} © Fjordland</p>
            </footer>
</section>

           <section className='imageSection' style={{ backgroundImage: `url(${image})`}}></section>
    </LoginStyle>

  )
}

export default Login