import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

`;
 
export default GlobalStyle;