import React, { useEffect, useState } from 'react';
import { PrintNewAPVStyle } from './PrintNewAPV.style';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests

function PrintNewAPV() {
  const navigate = useNavigate();
  const [num, setNum] = useState(0);
  const [newQuestion, setNewQuestion] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [handleplanShow, setHandleplanShow] = useState([null,null]);
  const [translateError, setTranslateError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [language, setLanguage] = useState("en");
  const [prevLanguage, setPrevLanguage] = useState("en");
  const [hoverIndex, setHoverIndex] = useState(null);
  console.log(JSON.parse(sessionStorage.getItem('apv')).APV);
  const [data, setData] = useState(JSON.parse(sessionStorage.getItem('apv')).APV || [
    {
      heading: 'Employee Performance Review',
      questions: [
        {
          question: 'How satisfied are you with your current tasks?',
          status: 'I orden',
          problem: '',
          explanation: '',
          deadline: '',
          responsible: '',
        },
        {
          question: 'What challenges are you facing in your role?',
          status: 'Udfordring',
          problem: '',
          explanation: '',
          deadline: '',
          responsible: '',
        },
      ],
    },
    {
      heading: 'Team Collaboration',
      questions: [
        {
          question: 'Do you feel your team communicates effectively?',
          status: 'Udfordring',
          problem: '',
          explanation: '',
          deadline: '',
          responsible: '',
        },
        {
          question: 'Are there any team activities you find irrelevant?',
          status: 'Irrelevant',
          problem: '',
          explanation: '',
          deadline: '',
          responsible: '',
        },
        {
          question: 'How would you rate your collaboration with team members?',
          status: 'I orden',
          problem: '',
          explanation: '',
          deadline: '',
          responsible: '',
        },
      ],
    },
  ]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const addNewQuestion = (index) => {
    data[index].questions.push({ 
      question: textInput, 
      status: '', 
      deadline: "", 
      explanation: "", 
      problem: "", 
      responsible: ""});
    setNum(num + 1);
    setNewQuestion(null);
  };

  const deleteQuestion = (sectionIndex, questionIndex) => {
    const newData = [...data];
    newData[sectionIndex].questions.splice(questionIndex, 1);
    setData(newData);
  };

  const addStatus = (sectionIndex, questionIndex, status) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].status = status;
    setData(newData);
    if (status === "Udfordring") {
          setHandleplanShow([sectionIndex, questionIndex])
    }

  };

  useEffect(() => {
    const h2Elements = Array.from(document.querySelectorAll('h2'));
    const optionElements = h2Elements.map((item, index) => (
      <p key={index} onClick={() => {document.getElementById(item.innerText.replace(' ', '-')).scrollIntoView({ behavior: 'smooth' }); setModalOpen(false)}}>{item.innerText}</p>
    ));
    setOptions(optionElements);
  }, []);

  const translateText = async (text) => {
    try {
      const response = await axios.post('https://lang.kvægapv.eu/translate', { text, targetLanguage: language, startLanguage: prevLanguage });
      return response.data.translatedText;
    } catch (error) {
      console.error('Translation error:', error);
      throw error;
    }
  };

  const translateAndAddQuestion = async (index) => {
    try {
      const translatedQuestion = await translateText(textInput);
      data[index].questions.push({ 
        question: translatedQuestion, 
        status: '', 
        deadline: "", 
        explanation: "", 
        problem: "", 
        responsible: "" 
      });
      setNum(num + 1);
      setNewQuestion(null);
    } catch (error) {
      // Handle translation error
      console.error('Translation error:', error);
      setTranslateError(true)
    }
  };

  const translateWholePage = async () => {
    try {
      const textElements = Array.from(document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button'));
      for (let i = 0; i < textElements.length; i++) {
        const text = textElements[i].innerText;
        const translatedText = await translateText(text);
        textElements[i].innerText = translatedText;
      }
    } catch (error) {
      console.error('Translation error:', error);
    }
  };

  return (
    <PrintNewAPVStyle>
      <div className='btnDiv'>
              <span id='theTop'></span>
      <button id='toTop' onClick={() => document.getElementById('theTop').scrollIntoView({ behavior: 'smooth' })}>⬆️</button>
      <button id='modalBtn' onClick={() => setModalOpen(modalOpen ? false : true)}>{'='}</button>
      <button onClick={translateWholePage}>Translate Page</button>
      <select onChange={(e) => {
        setPrevLanguage(language)
        setLanguage(e.target.value)
        
        }} name="" id="">
        <option value="da">Dansk</option>
        <option value="pl">Polsk</option>
        <option value="es">Spansk</option>
        <option value="fr">Fransk</option>
        <option value="en">Engelsk</option>
        <option value="uk">Ukrainsk</option>
      </select>
      <button onClick={() => {
        document.getElementsByClassName('btnDiv')[0].style.display = "none"
        document.getElementsByClassName('btnDiv')[1].style.display = "none"
        document.getElementsByTagName('header')[0].style.display = "none"
        document.getElementsByClassName('desktopForm')[0].style.width = "90vw"
        document.getElementsByClassName('desktopForm')[0].style.marginLeft = "5vw"
        document.getElementsByClassName('desktopForm')[0].style.marginTop = "2vw"
        window.print()
        setTimeout(() => {
          document.getElementsByClassName('btnDiv')[0].style.display = "block"
          document.getElementsByClassName('btnDiv')[1].style.display = "block"
          document.getElementsByTagName('header')[0].style.display = "block"
          document.getElementsByClassName('desktopForm')[0].style.width = "80vw"
          document.getElementsByClassName('desktopForm')[0].style.marginLeft = "0vw"
        }, 200);
      }}>print</button>
      </div>
      
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            {options}
          </div>
        </div>
      )}
 
     { !isDesktop ? <section key={num} className='mobileForm'>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <h2 id={item.heading.replace(' ', '-')}>{item.heading}</h2>
            {item.questions.map((question, qIndex) => (
              <div key={qIndex}>
                <p ></p>
                <p>{question.question}</p>
                <button
                  style={{ backgroundColor: question.status === 'Udfordring' ? 'red' : 'initial' }}>
                  Challange
                </button>
                <button
                  style={{ backgroundColor: question.status === 'I orden' ? 'green' : 'initial' }}>
                  No problem
                </button>
                <button
                  style={{ backgroundColor: question.status === 'Irrelevant' ? 'gray' : 'initial' }}>
                  Irrelevant
                </button>
              </div>
            ))}

          </React.Fragment>
        ))}
      </section>
      :
<section key={num} className='desktopForm'>
  {data.map((item, index) => (
    <React.Fragment key={index}>
      <div>
        <div className='categoryDiv' style={{display: data[index].questions.length > 0 ? "grid" : "none"}}>
          <p id={item.heading.replace(' ', '-')} style={{backgroundColor: "#D9D9D9"}}>{item.heading}</p>
          <p style={{backgroundColor: "#CF4040"}}>Udfordring</p>
          <p style={{backgroundColor: "#28C253"}}>I orden</p>
          <p style={{backgroundColor: "#B8B8B8"}}>Irrelevant</p>
        </div>
        {item.questions.map((question, qIndex) => (
          <React.Fragment key={`${index}-${qIndex}`}>
            <article className='headingArt'>
              <p ></p>
              <div>
                <span><p>{question.question}</p><p>?</p></span>
              </div>
              <div>{question.status === "Udfordring" ? "x" : ""}</div>
              <div >{question.status === "I orden" ? "x" : ""}</div>
              <div >{question.status === "Irrelevant" ? "x" : ""}</div>
            </article>
            <article className='handleplanArt' style={{display: index === handleplanShow[0] && qIndex === handleplanShow[1] ? "grid" : "none"}}>
              <div>
                <h3>Beskriv problemet</h3>
                <textarea name="" id="" cols="30" rows="5"></textarea>
              </div>
              <div className='deadlineDiv'>
                <h3>Deadline</h3>
                <div className='deadlineInputDiv'>
                  <label htmlFor="">Løses straks</label><input type="radio" name="" id="" /> 
                  <label htmlFor="">Løses inden </label><input type="date" name="" id="" />
                </div>
              </div>
              <div>
                <h3>Ansvarlig</h3>
                <input placeholder='Ansvarlig' type="text" />
              </div>
              <div>
                <button>Gem</button>
                <button onClick={() => setHandleplanShow([null,null])}>Senere</button>
              </div>
            </article>
          </React.Fragment>
        ))}

      </div>
    </React.Fragment>
  ))}
</section>


}
      <div className='btnDiv' style={{display: isDesktop ? "grid" : "grid"}}>
        <button onClick={() => navigate("/clientpage")}>Tilbage</button>
      </div>
    </PrintNewAPVStyle>
  );
}

export default PrintNewAPV;
