import { useEffect, useState } from "react";
import Approuter from "./Approuter/Approuter";
import Header from "./Components/Header/Header";
import GlobalStyle from "./Global.style";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
console.log("app");
  useEffect(() => {
    if (sessionStorage.getItem('user')) {
        setLoggedIn(true)
    }else{

    }

    let interval = setInterval(() => {
      if (sessionStorage.getItem('user')) {
        setLoggedIn(true)
        clearInterval(interval)
    }
    }, 500);
}, []);

  useEffect(() => {


    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      
      <main style={{
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column', // Side by side on desktop, stacked on mobile
      }}>
        {loggedIn ? <Header /> : null}
        <Approuter />
      </main>
    </div>
  );
}

export default App;
