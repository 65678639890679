import React from 'react'
import { FrontpageStyle } from './Frontpage.style'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import Header from '../../Components/Header/Header'

function Frontpage() {
  const navigate = useNavigate(); // Get the navigation function

  return (
    <FrontpageStyle>

      <button className='Btn1' onClick={() => navigate('/newapv1')}> Opret APV</button>
      <button className='Btn2' onClick={() => navigate('/newclient')}> Opret kunde</button>
      <button className='Btn3' onClick={() => navigate('/search')}> Søg kunde</button>
      <button className='Btn4' onClick={() => navigate('/history')}> Historik</button>
    </FrontpageStyle>
  )
}

export default Frontpage
