import React, { useEffect, useState } from 'react';
import { PreAPVStyle } from './PreAPV.style';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function PreAPV() {
  const navigate = useNavigate(); // Get the navigation function
  const [selection, setSelection] = useState([])
  const [farmers, setFarmers] = useState('');
  const [data, setData] = useState("");
  const [newAPV, setNewAPV] = useState({"APV": []});
  const { register, handleSubmit, formState: { errors } } = useForm()
  console.log(JSON.parse(sessionStorage.getItem('user')));
  const submitForm = (data) => {
      console.log(data);
      createNew(data.apvName,data.farmer,"","")
      console.log(document.querySelectorAll('input')[1].value);
    }
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://api.kvægapv.eu/getAllLists');
          const response1 = await axios.get('https://api.kvægapv.eu/getAllFarmers');
          setData(response.data);
          setSelection(response.data)
          setFarmers(response1.data);
          console.log(response.data);
          console.log(response1.data);
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData();
  
      // Cleanup function
      return () => {
        // Any cleanup code...
      };
    }, []); // Empty dependency array ensures the effect runs only once
    const [clients, setClients] = useState([
      "Henrik Madsen - 2343424",
      "John Doe",
      "Henrik Madsen",
      "Henrik Madsen",
      "Henrik Madsen",
    ])
console.log(newAPV);
    const [bilag, setBilag] = useState([
      "Gift.pdf",
      "GammelAPV.dosx"
    ])
    const createNew = async (name,clientId,content,createdBy) => {
      try {
          const response = await axios.post('https://api.kvægapv.eu/createapv', {
              name: name,
              clientId: clientId,
              content: JSON.stringify({ "APV": [] }), // Ensure content is stringified
              createdBy: JSON.parse(sessionStorage.getItem('user')).id
          });
          console.log('APV created successfully:', response.data);
          alert("Succes!")
          navigate(`/newapv/id/${response.data.id}`)
      } catch (error) {
          console.error('Creation error:', error);
          throw error;
      }
  };
  
  const handleSort = (e) => {
    let array = []

    data.map((item) =>{
      if (item.type === e) {
        array.push(item)
      }
    })
    setSelection(array)
    console.log(array);
  }
    return (
        <PreAPVStyle>
                {!data && <h2 style={{position: "absolute", left: "38vw", top: "40vh"}}>Gør tingene klar...</h2>}
{data && (<form onSubmit={handleSubmit(submitForm)}>
              <section >
                <div>
                <div className='clientInput'>
  <h2>Evt. Navn på APV</h2>
    <input type="text" {...register('apvName')} />
  {errors.client && <span>Du skal skrive navnet på landmanden</span>}
</div>
                {/* <div className='dateInput'>
                <h2>Dato for besøg</h2>
              <input type="date" name="date" id='date' placeholder='Søg..' {...register('date')}/>
              {errors.date && <span>Du skal angive datoen for besøg!</span>}
              </div> */}
              
              <div className='clientInput'>
  <h2>Landmand</h2>
  <select name="farmer" id="" {...register('farmer')}>
  {farmers.map((item, index) => (
    <option key={index} value={item.id}>{item.firstname} {item.middlename} {item.lastname} - {item.cvr}</option>
  ))}
</select>
  {errors.client && <span>Du skal skrive navnet på landmanden</span>}
</div>
                </div>


              </section>
              
              <section className='listSec'>
                <div>                
                  <h2>Lister</h2> 
                              {/* <select name="" id="" onChange={(e) => handleSort(e.target.value)}>
                {[...new Set(data.map(item => item.type))].map(type => (
  <option key={type} value={type}>{type}</option>
))}

                </select> */}
 
                </div>

                
                <article className='listArt'>
                  {
                    selection.map((item,index) =>(
                      <div className='listDiv' key={index}>
                  <p>{item.name}</p>
                  <input id={`input${index}`} type="checkbox" {...register(`checkbox${index}`)} onChange={(e) => {
                    if (e.target.checked) {
                                 item.content.APV.map((item,index) =>{
                      newAPV.APV.push(item)
                      
                    })
                    sessionStorage.setItem('apv', JSON.stringify(newAPV));
                    console.log(JSON.parse(sessionStorage.getItem('apv')));
                    
                    console.log(newAPV);
                    }
    
                    }}/>
                </div>
                    ))
                  }
                </article>
              </section>
              
              <br />

              <article className='btnArt'>
                <button className='clearAll' type='reset' onClick={() => setNewAPV({"APV": []})} >Ryd alt</button>
                        <button type='submit'>Videre</button>
                    
                        
              </article>

            </form>) }
            <button onClick={() => createNew()}>NY</button>
            <button onClick={() => {

              navigate('/printnewapv')
              }}>Print</button>
        </PreAPVStyle>
    );
}

export default PreAPV;
