import React, { useEffect, useState } from 'react';
import { EditAPVStyle } from './EditAPV.style';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Handleplan from '../Handleplan/Handleplan';
import genereltTranslation from "../../Assets/ListTranslations/Generelt.json"

function EditAPV() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [num, setNum] = useState(0);
  const [newQuestion, setNewQuestion] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [handleplanShow, setHandleplanShow] = useState([null,null]);
  const [translateError, setTranslateError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [language, setLanguage] = useState("en");
  const [prevLanguage, setPrevLanguage] = useState("en");
  const [hoverIndex, setHoverIndex] = useState(null);
  const [farmerId, setFarmerId] = useState(null);
  const [data, setData] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
console.log(id);
  const handleUpdate = () => {
    updateAPV(id,data)
    setHandleplanShow([null,null])
  }
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const addNewQuestion = (index) => {
    data[index].questions.push({ 
      question: textInput, 
      status: '', 
      deadline: "", 
      explanation: "", 
      problem: "", 
      responsible: ""});
    setNum(num + 1);
    setNewQuestion(null);
  };

  const deleteQuestion = (sectionIndex, questionIndex) => {
    const newData = [...data];
    newData[sectionIndex].questions.splice(questionIndex, 1);
    setData(newData);
  };

  const addStatus = (sectionIndex, questionIndex, status) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].status = status;
    setData(newData);
    if (status === "Udfordring") {
          setHandleplanShow([sectionIndex, questionIndex])
    }

  };

  const addDeadline = (sectionIndex, questionIndex, deadline) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].deadline = deadline;
    setData(newData);

  };

  const addExplantion = (sectionIndex, questionIndex, explanation) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].explanation = explanation;
    setData(newData);

  };

  const addResponsible = (sectionIndex, questionIndex, responsible) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].responsible = responsible;
    setData(newData);

  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.kvægapv.eu/getApv/${id}`);
        setData(response.data.content);
        setFarmerId(response.data.clientId);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    
    // Cleanup function
    return () => {
    
    };
  }, []);
  useEffect(() => {

      const h2Elements = Array.from(document.querySelectorAll('h2'));
    const optionElements = h2Elements.map((item, index) => (
      <p key={index} onClick={() => {document.getElementById(item.innerText.replace(' ', '-')).scrollIntoView({ behavior: 'smooth' }); setModalOpen(false)}}>{item.innerText}</p>
    ));
    setOptions(optionElements);

    
  }, [data]);

  const translateText = async (text) => {
    try {
        // Send the text for translation and receive the translated text
        const response = await axios.post('https://lang.kvægapv.eu/translate', { text, targetLanguage: language, startLanguage: prevLanguage });
        return response.data.translatedText;
    } catch (error) {
        // Handle translation errors
        console.error('Translation error:', error);
        throw error;
    }
};

const translateJSON = async (json) => {
    try {
        // Iterate through the JSON object
        for (const section of json) {
            // Check if the section has a 'questions' key
            if (section.questions && Array.isArray(section.questions)) {
                // Iterate through the questions array
                for (const questionObj of section.questions) {
                    // Check if the question object has a 'question' key
                    if (questionObj.question) {
                        // Extract the text to translate
                        const textToTranslate = questionObj.question[prevLanguage];
                        
                        // Translate the text
                        const translatedText = await translateText(textToTranslate);
                        
                        // Update the JSON with translated text
                        questionObj.question[language] = translatedText;
                    }
                }
            }
        }
        // Return the modified JSON with translated text
        return json;
    } catch (error) {
        console.error('Translation error:', error);
        throw error;
    }
};


  
  const translateAndAddQuestion = async (index) => {
    try {
      const translatedQuestion = await translateText(textInput);
      data[index].questions.push({ 
        question: translatedQuestion, 
        status: '', 
        deadline: "", 
        explanation: "", 
        problem: "", 
        responsible: "" 
      });
      setNum(num + 1);
      setNewQuestion(null);
    } catch (error) {
      // Handle translation error
      console.error('Translation error:', error);
      setTranslateError(true)
    }
  };

  const translateWholePage = async (json) => {
    try {
        // Check if json is null or undefined
        if (!json) {
            console.error('JSON data is null or undefined');
            return;
        }

        // Check if json is an iterable object (array or iterable)
        if (typeof json !== 'object' || !Symbol.iterator in Object(json)) {
            console.error('JSON data is not iterable');
            return;
        }

        // Translate the JSON
        const translatedJSON = await translateJSON(json);
        
        const textElements = Array.from(document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button'));
        for (let i = 0; i < textElements.length; i++) {
            const text = textElements[i].innerText;
            // Check if the text matches any Danish translation in the JSON
            const matchedText = findMatchedText(translatedJSON, text);
            if (matchedText) {
                const translatedText = await translateText(matchedText);
                textElements[i].innerText = translatedText;
            }
        }
    } catch (error) {
        console.error('Translation error:', error);
    }
};


const findMatchedText = (json, text) => {
    // Iterate through the JSON object
    for (const section of json) {
        // Check if the section has a 'questions' key
        if (section.questions && Array.isArray(section.questions)) {
            // Iterate through the questions array
            for (const questionObj of section.questions) {
                // Check if the question object has a 'question' key with Danish translation
                if (questionObj.question && questionObj.question.da === text) {
                    // Return the matched text
                    return questionObj.question[language];
                }
            }
        }
    }
    return null;
};

  const updateAPV = async (id,content) => {
    console.log(id);
    try {
        const response = await axios.put(`https://api.kvægapv.eu/updateapv/${id}`, {
            content: JSON.stringify(content), // Ensure content is stringified
        });
        //console.log('APV updated successfully:', response.data);
        alert("Succes!")

    } catch (error) {
        console.error('Update error:', error);
        throw error;
    }
};

console.log(genereltTranslation.APV);
  return (
    <EditAPVStyle>
{ data ?      <>
  <div className='btnDiv'>
              <span id='theTop'></span>
      <button id='toTop' onClick={() => document.getElementById('theTop').scrollIntoView({ behavior: 'smooth' })}>⬆️</button>
      <button id='modalBtn' onClick={() => setModalOpen(modalOpen ? false : true)}>{'='}</button>
      <button onClick={() => translateWholePage(genereltTranslation.apv)}>Translate Page</button>
      <select onChange={(e) => {
        setPrevLanguage(language)
        setLanguage(e.target.value)
        
        }} name="" id="">
        <option value="da">Dansk</option>
        <option value="pl">Polsk</option>
        <option value="es">Spansk</option>
        <option value="fr">Fransk</option>
        <option value="en">Engelsk</option>
        <option value="uk">Ukrainsk</option>
      </select>
      <button onClick={() => {
        document.getElementsByClassName('btnDiv')[0].style.display = "none"
        document.getElementsByClassName('btnDiv')[1].style.display = "none"
        document.getElementsByTagName('header')[0].style.display = "none"
        document.getElementsByClassName('desktopForm')[0].style.width = "90vw"
        document.getElementsByClassName('desktopForm')[0].style.marginLeft = "5vw"
        document.getElementsByClassName('desktopForm')[0].style.marginTop = "2vw"

        window.print()
        setTimeout(() => {
          document.getElementsByClassName('btnDiv')[0].style.display = "block"
          document.getElementsByClassName('btnDiv')[1].style.display = "block"
          document.getElementsByTagName('header')[0].style.display = "block"
          document.getElementsByClassName('desktopForm')[0].style.width = "80vw"
          document.getElementsByClassName('desktopForm')[0].style.marginLeft = "0vw"
        }, 200);
      }}>print</button>
      </div>
      
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            {options}
          </div>
        </div>
      )}
 
     { !isDesktop ? <section key={num} className='mobileForm'>
        {data.length < 0 ? data.map((item, index) => (
          <React.Fragment key={index}>
            <h2 id={item.heading.replace(' ', '-')}>{item.heading}</h2>
            {item.questions.map((question, qIndex) => (
              <div key={qIndex}>
                <p onClick={() => deleteQuestion(index, qIndex)}>X</p>
                <p>{question.question}</p>
                <button
                  style={{ backgroundColor: question.status === 'Udfordring' ? 'red' : 'initial' }}
                  onClick={() => addStatus(index, qIndex, 'Udfordring')}
                >
                  Challange
                </button>
                <button
                  style={{ backgroundColor: question.status === 'I orden' ? 'green' : 'initial' }}
                  onClick={() => addStatus(index, qIndex, 'I orden')}
                >
                  No problem
                </button>
                <button
                  style={{ backgroundColor: question.status === 'Irrelevant' ? 'gray' : 'initial' }}
                  onClick={() => addStatus(index, qIndex, 'Irrelevant')}
                >
                  Irrelevant
                </button>
              </div>
            ))}
            {newQuestion === null ? (
              <button onClick={() => setNewQuestion(index)}>Nyt spørgsmål</button>
            ) : null}
            {newQuestion === index ? (
              <div>
                <input type="text" onChange={(e) => setTextInput(e.target.value)} />
                <button onClick={() => translateAndAddQuestion(index)}>Tilføj</button>
                <button onClick={() => setNewQuestion(null)}>Annuller</button>
              </div>
            ) : null}
          </React.Fragment>
        )) : <h2>Denne APV er tom</h2>}
      </section>
      :
<section key={num} className='desktopForm'>
  
  {data.map((item, index) => (
    <React.Fragment key={index}>
      <div onMouseOver={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
        <div className='categoryDiv' style={{display: data[index].questions.length > 0 ? "grid" : "none"}}>
          <h2 id={item.heading.replace(' ', '-')} style={{backgroundColor: "#D9D9D9"}}>{item.heading}</h2>
          <p style={{backgroundColor: "#CF4040"}} onClick={() => {
            item.questions.map((question, qIndex) => {
              addStatus(index, qIndex, 'Udfordring')
            })
          }}>Udfordring</p>
          <p style={{backgroundColor: "#28C253"}} onClick={() => {
            item.questions.map((question, qIndex) => {
              addStatus(index, qIndex, 'I orden')
            })
          }}>I orden</p>
          <p style={{backgroundColor: "#B8B8B8"}} onClick={() => {
            item.questions.map((question, qIndex) => {
              addStatus(index, qIndex, 'Irrelevant')
            })
          }}>Irrelevant</p>
        </div>
        {item.questions.map((question, qIndex) => (
          <React.Fragment key={`${index}-${qIndex}`}>
            <article className='headingArt'>
              <p onClick={() => deleteQuestion(index, qIndex)}>X</p>
              <div>
                <span><p>{question.question}</p><p>?</p></span>
              </div>
              <div onClick={() => addStatus(index, qIndex, 'Udfordring')}>{question.status === "Udfordring" ? "x" : ""}</div>
              <div onClick={() => addStatus(index, qIndex, 'I orden')}>{question.status === "I orden" ? "x" : ""}</div>
              <div onClick={() => addStatus(index, qIndex, 'Irrelevant')}>{question.status === "Irrelevant" ? "x" : ""}</div>
            </article>
            <article className='handleplanArt' style={{display: index === handleplanShow[0] && qIndex === handleplanShow[1] ? "grid" : "none"}}>
              <div>
                <h3>Beskriv problemet</h3>
                <textarea name="" id="" cols="30" rows="5" defaultValue={question.explanation} onChange={(e) => addExplantion(index, qIndex, e.target.value)}></textarea>
              </div>
              <div className='deadlineDiv'>
                <h3>Deadline</h3>
                <div className='deadlineInputDiv'>
                  <label htmlFor="">Løses straks</label><input type="radio" name="" id="" defaultValue={question.deadline} onChange={(e) => addDeadline(index, qIndex, new Date().toLocaleDateString('en-GB'))}/> 
                  <label htmlFor="">Løses inden </label><input type="date" name="" id="" defaultValue={question.deadline} onChange={(e) => addDeadline(index, qIndex, new Date(e.target.value).toLocaleDateString('en-GB'))} />
                </div>
              </div>
              <div>
                <h3>Ansvarlig</h3>
                <input placeholder='Ansvarlig' type="text" defaultValue={question.responsible} onChange={(e) => addResponsible(index, qIndex, e.target.value)}/>
              </div>
              <div>
                <button onClick={() => {
                  updateAPV(id,data)
                  setHandleplanShow([null,null])
                }}>Gem</button>
                <button onClick={() => setHandleplanShow([null,null])}>Senere</button>
              </div>
            </article>
          </React.Fragment>
        ))}
        {hoverIndex === index ? (
          <button className='newQuestion' onClick={() => setNewQuestion(index)}>Add Question</button>
        ) : null}
        {newQuestion === index ? (
          <article key={`new-${index}`} className='headingArt'>
            <p onClick={() => deleteQuestion(index, item.questions.length)}>X</p>
            <div>
              <input
                type="text"
                placeholder='Skriv nyt spørgsmål her...'
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    if (language === "en") {
                      addNewQuestion(index);
                    } else {
                      setTimeout(() => {
                        if (translateError) {
                        addNewQuestion(index);
                        setTranslateError(false);
                      } else {
                        translateAndAddQuestion(index);
                      }
                      }, 500);
                      
                    }
                    setNewQuestion(null);
                    setTextInput("");
                  }
                }}
              />
            </div>
            <div onClick={() => addStatus(index, item.questions.length, 'Udfordring')}></div>
            <div onClick={() => addStatus(index, item.questions.length, 'I orden')}></div>
            <div onClick={() => addStatus(index, item.questions.length, 'Irrelevant')}></div>
          </article>
        ) : null}
      </div>
    </React.Fragment>
  ))}
</section>


}
      <div className='btnDiv' style={{display: isDesktop ? "grid" : "grid"}}>
        <button onClick={() => handleUpdate()}>Gem APV</button>
        <button onClick={() => {
          handleUpdate()
          setTimeout(() => {
             navigate(`/handleplaner/${id}`)
          }, 500);
         }}>Handleplaner</button>
        <button onClick={() => navigate("/dashboard")}>Slet</button>
      </div>
      
      </> : <h2 style={{position: "absolute", left: "38vw", top: "40vh"}}>Henter apv'en...</h2>}
    </EditAPVStyle>
  );
}

export default EditAPV;
