import React, { useEffect, useState } from 'react';
import { NewClientStyle } from './NewClient.style';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function NewClient() { 
  const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate(); // Get the navigation function
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cvr, setCvr] = useState('');
  const [isAPV, setIsAPV] = useState(false);
  const [isAutoFill, setIsAutoFill] = useState(false);
  const [autoFillData, setAutoFillData] = useState([]);
  const [data, setData] = useState("");
  const [id, setId] = useState(0);
  function splitFullName(fullName) {
    const parts = fullName.split(' ');
    let firstName = parts[0];
    let middleName = '';
    let lastName = '';

    // If there are more than two parts, assume the last part as the last name
    if (parts.length > 1) {
        lastName = parts.pop(); // Get the last part as last name
        // If there are still more parts, assume the first part as the first name
        if (parts.length > 1) {
            firstName = parts.shift(); // Get the first part as first name
            // If there are more parts remaining, they are middle names
            middleName = parts.join(' '); // Join the remaining parts as middle name
        }
    }

    return {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName
    };
}


  const submitForm = (data) => {
if (!isAutoFill) {
  const nameParts = splitFullName(client);
    const firstName = nameParts.firstName;
    const middleName = nameParts.middleName;
    const lastName = nameParts.lastName;

    console.log(email, firstName, middleName, lastName, data.cvr, phone);
    if (isAPV) {
      createNewAPV(data.apvName,"","",1,email, firstName, middleName, lastName, data.cvr, phone)
    }else{
      createNewClient(email, firstName, middleName, lastName, data.cvr, phone);
    }

}
    
    
}

    const [bilag, setBilag] = useState([])

    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://api.kvægapv.eu/getAllLists');
          const response1 = await axios.get('https://api.kvægapv.eu/getAllFarmers');
          setData(response.data);
          setId(response1.data.length + 1)
          console.log(response.data);
          console.log(response1.data.length + 1);
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData();
  
      // Cleanup function
      return () => {
        // Any cleanup code...
      };
    }, []);

    const handleCVRFetch = async () => {
      setIsAutoFill(true)
      try {
        const response = await axios.get(`https://cvrapi.dk/api?search=${document.getElementById('cvr').value}&country=dk`);
        setAutoFillData(response.data);
        setEmail(response.data.email)
        setPhone(response.data.phone)
        setClient(response.data.owners[0].name)
        console.log(response.data);

      } catch (error) {
        console.log(error);
      }
    };
// Function to handle input changes and sanitize them
const handleClientChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setClient(sanitizedValue);
};

const handleEmailChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setEmail(sanitizedValue);
};

const handlePhoneChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setPhone(sanitizedValue);
};
function generateRandomPassword(length = 6) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password += chars[randomIndex];
  }
  return password;
}
const createNewClient = async (email,firstname,middlename,lastname,cvr,phone) => {
  const generatedPass = generateRandomPassword()
  sessionStorage.setItem(`tempPass${id}`, JSON.stringify(generatedPass))
  try {
      const response = await axios.post('https://api.kvægapv.eu/create-client', {
        email:email,
        password:generatedPass,
        firstname:firstname,
        middlename:middlename || null,
        lastname:lastname,
        cvr:cvr,
        phone:phone
      });
      console.log('Client created successfully:', response.data);
      alert("Succes!")
  } catch (error) {
    alert(error.response.data);
      console.error('Creation error:', error);
      
  }
};

const createNewAPV = async (name,clientId,content,createdBy,email,firstname,middlename,lastname,cvr,phone) => {
  try {
    const response = await axios.post('https://api.kvægapv.eu/create-client', {
      email:email,
      password:"pass",
      firstname:firstname,
      middlename:middlename || null,
      lastname:lastname,
      cvr:cvr,
      phone:phone
    });
    console.log('Client created successfully:', response.data);
    alert("Succes!")

      const response1 = await axios.post('https://api.kvægapv.eu/createapv', {
          name: name,
          clientId: response.data.id,
          content: JSON.stringify({ "APV": [] }), // Ensure content is stringified
          createdBy: JSON.parse(sessionStorage.getItem('user')).id
      });
      console.log('APV created successfully:', response.data);
      alert("Succes!")
      navigate(`/newapv/id/${response1.data.id}`)
  } catch (error) {
      console.error('Creation error:', error);
      throw error;
  }
};

const handleFileUpload = event => {
  const file = event.target.files[0];
  const targetFolder = document.getElementById('cvr').value
  if (!file) return;

  const formData = new FormData();
  formData.append('file', file);

  fetch('https://api.kvægapv.eu/fileUpload', {
    headers: {
      'filename': file.name,
      'targetfolder':"/" + targetFolder,
      'clientid': id
    },
    method: 'POST',
    body: formData
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      setBilag([...bilag, file.name]);
      alert('File uploaded successfully');
    } else {
      alert('Error uploading file: ' + data.description);
    }
  })
  .catch(error => {
    console.error('Upload error:', error);
    alert('Upload failed: ' + error.message);
  });
};


const handleFileDelete = filename => {
  fetch(`https://api.kvægapv.eu/fileDelete?filename=${encodeURIComponent(filename)}&clientId=${encodeURIComponent(id)}`, {
    method: 'DELETE'
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      setBilag(bilag.filter(item => item !== filename));
      alert('File deleted successfully');
    } else {
      alert('Error deleting file: ' + data.description);
    }
  })
  .catch(error => {
    console.error('Delete error:', error);
    alert('Delete failed: ' + error.message);
  });
};

    return (
        <NewClientStyle>
{data ? <form id='myForm' onSubmit={handleSubmit(submitForm)}>
            <div className='cvrInput'>
                <h2>CVR</h2>
              <input type="number" name="cvr" id='cvr'  placeholder='Skriv CVR' {...register('cvr', {required: true})}/>
              {errors.cvr && <span>Du skal skrive CVR nummeret!</span>} 
              <br />          
              <button onClick={(e) => handleCVRFetch(e)}>Søg og udfyld</button>
              </div>
   
              <section >
              {/* <div className='dateInput'>
                <h2>Dato for besøg</h2>
              <input type="date" name="date" id='date' placeholder='Søg..' {...register('date', {required: true})}/>
              {errors.date && <span>Du skal angive datoen for besøg!</span>}
              </div> */}
              <div className='clientInput'>
  <h2>Evt. Navn på APV</h2>
    <input type="text" {...register('apvName')} />
  {errors.client && <span>Du skal skrive navnet på landmanden</span>}
</div>
              <div className='clientInput'>
  <h2>Landmand</h2>
  <input 
    type="text" 
    name="client" 
    id='client' 
    placeholder='Skriv fulde navn' 
    value={client} // Bind input value to state
    onChange={handleClientChange} // Call sanitization function on change
    onBlur={handleClientChange} // Additional sanitization on blur
  />
  {errors.client && <span>Du skal skrive navnet på landmanden</span>}
</div>

<div className='emailInput'>
  <h2>Email</h2>
  <input 
    type="text" 
    name="email" 
    id='email' 
    placeholder='Skriv email' 
    value={email} // Bind input value to state
    onChange={handleEmailChange} // Call sanitization function on change
    onBlur={handleEmailChange} // Additional sanitization on blur
  />
  {errors.email && <span>Du skal skrive emailen</span>}
</div>

<div className='phoneInput'>
  <h2>Telefonnummer</h2>
  <input 
    type="tel" 
    name="phone" 
    id='phone' 
    placeholder='Skriv nummer' 
    value={phone} // Bind input value to state
    onChange={handlePhoneChange} // Call sanitization function on change
    onBlur={handlePhoneChange} // Additional sanitization on blur
  />
  {errors.phone && <span>Du skal skrive telefon nummeret</span>}
</div>

              </section>
              <section className='listSec'>
                <h2>Lister</h2>
                <article className='listArt'>
                  {
                    data.map((item,index) =>(
                      <div className='listDiv'>
                  <p>{item.name}</p>
                  <input id={`input${index}`} type="checkbox" />
                </div>
                    ))
                  }
                </article>
                
              </section>
              <div className="bilagSec">
            <h3>Bilag</h3>
            <div>
              <input type="file" onChange={(e) => {
                if (!document.getElementById('cvr').value) {
                  alert('Du kan ikke uploade uden at skrive CVR nummer')
                  return false
                }else{
                  setCvr(document.getElementById('cvr').value)
                  handleFileUpload(e)
                }
                }} />
            </div>
            {bilag.length > 0 ? (
              bilag.map((file, index) => (
                <div key={index}>
                  <p>{file}</p>
                  <button onClick={() => handleFileDelete(file)}>Delete</button>
                </div>
              ))
            ) : (
              <p>Ingen bilag endnu</p>
            )}
          </div>
                    <br />
              <article className='btnArt'>
                <button className='clearAll' type='reset' onClick={() => {
                  setClient("")
                  setEmail("")
                  setPhone("")
                }} >Ryd alt</button>
                <button onClick={() => {
                  setIsAutoFill(false)
                  setIsAPV(false)}}>Opret ny kunde</button>
                        <button onClick={() => {
                          setIsAutoFill(false)
                          setIsAPV(true)
                          }}>Opret APV</button>
                        
              </article>

            </form> : <h2 style={{position: "absolute", left: "38vw", top: "40vh"}}>Gør det hele klar...</h2>}
        </NewClientStyle>
    );
}

export default NewClient;
