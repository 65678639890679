import { styled } from "styled-components";

export const HandleplanStyle = styled.section`
margin-top: 4vh;
h2{
    margin-top: 2vh;
    border-bottom: solid black 2px;
    width: fit-content;
    margin-bottom: 2vh;
}
h4{
    margin-top: 2vh;
    width: fit-content;
    
}
.handleplanArt{
    display: grid;
    grid-template-columns: 35% 40% 25%;
    border: solid 1px black;
    width: fit-content;
    h3{
        border-bottom: solid black 1px;
        margin-bottom: 1vh;
    }
    textarea{
        font-size: 20px;
        width: 100%;

    }
    div{
         
        border-right: solid black 1px; 
        padding: 1vh 1vw;
    }
    span{
        display: flex;
        justify-content: space-between;
               p{
        height: 5vh;
    } 

    }
    input{
        width: 100%;
        height: 4vh;
        margin-top: 4vh;

        text-align: center;
        border: none;
        border-bottom: solid black 1px;
        font-size: 20px;
    }

    button{
        width: auto;
        height: 7vh;
        margin-top: 1vh;
    }
    .deadlineInputDiv{    
    display: grid;
    grid-template-columns: 30% 60%;
    justify-items: center;
    border-right: none;
        label{
            padding-top: 4vh;
        width: 7vw;
    }
    input:first-of-type{  
        height: 2vh;
        margin-top: 4vh;
    }
    input:last-of-type{  
        margin-left: 4vw;
        height: 3vh;
        margin-top: 4vh;
        width: 16.5vw;
    }
}

}
    
`