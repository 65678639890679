import { styled } from "styled-components";

export const ClientPageStyle = styled.article`
margin-left: 4vw;
margin-right: 4vw;  

display: grid;  
#modalBtn{
    position: absolute;
    top: 20px;
    right: 30px;
    height: 5vh;
    width: 10vw;
    font-size: 30px;
    font-weight: bold;
    
}

.modal{
    position: fixed;
    left: 10px;
    right: 10px;
    top: 200px;
    background-color: #007297;
    border: solid black 1px;
    height: auto;
    .modal-content{
        background-color: #007297;  
        height: fit-content;
        width: auto;
        h2{
            background-color: #FFFFFF;
            margin: 1vh 2vw;
            padding: 1vh 1vw;
            border-radius: 6px;
            font-size: 17px;
            text-align: center;
            
            
        }
        div{
            display: grid;
            grid-template-columns: 50% 25% 25%;
            
            text-align: center;
                p{
                border-top: solid black 1px;
                border-right: solid black 1px;
                height: 17vh;
                padding-top: 1vh;
            }
            h4{
                border-right: solid black 1px;
            }
        }

    }
}
h2{
    font-weight: 300;
} 
span{
    text-align: center;
    font-weight: bold;
    margin-top: 1vh;
}
section{
    display: grid;
    grid-template-columns: 1fr;
}

.cvrInput
,.dateInput
,.clientInput
,.emailInput
,.phoneInput
,.changedInput{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    span{
        display: block;
    }
        input{
        text-align: center;
        color: white;
    font-size: 25px;
    border: none;
    border-bottom: solid white 2px;
    padding-bottom: 1vh;
    background-color: #007297;
    width: 80%;

}

input::placeholder{
    color: #FFFFFF;
    opacity: 54%;
}
}
.changedInput{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}
.APVSec{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    height: 30vh;
    overflow: auto;
    h2{
        height: 10vh;
    }
}
.APVDiv{
    display: flex;
    justify-content: space-evenly;
    border-bottom: solid white 1px;
    padding: 2vh 2vw;

    input{
        margin-right: 8vw;
    }
}

.ProblemSec{
    margin-bottom: 2vh;
    text-align: center;
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    height: fit-content;
    overflow: auto;
    h2{
        height: 10vh;
    }
    h3{
        font-weight: 200;
    }
}
.ProblemDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    border-bottom: solid white 1px;
    padding: 2vh 2vw;
    p:last-of-type{
        padding-top: 3vh;
        padding-right: 2vw;
    }
    p:first-of-type{
        text-align: start;
    }
    button{
        width: 10vw;
        height: 30px;
        width: 30px;
        border-radius: 50px;
        border: black solid 1px;
        font-size: 20px;
    }
}

.bilagSec{

    text-align: center;
    background-color: #007297;
    border: none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    height: 30vh;
    overflow: auto;
    h2{
        height: 10vh;
    }
}
.bilagDiv{
    display: grid;
    grid-template-columns: 80% 20%;
    justify-items: center;
    border-bottom: solid white 1px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    text-align: center;
    p{
        margin-left: 8vh;
    }
    button{
        margin-right: 6vw;
        border: none;
        width: 20vw;
        height: 4vh;
        border-radius: 6px;
    }
}
.uploadBtn{
    border: none;
    background-color: #007297;
    color: #FFFFFF;
    font-size: 21px;
    font-weight: bold;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 6vh;
    padding: 2vh, 4vw;
    width: 100%;
}

.clearAll{
    background-color: #CF4040;
}

.editBtn{
    background-color: #C3BD18;
}
article{
    width: 98%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 2vw;
    margin-top: 2vh;
    height: 12vh;
    button{
    background-color: #007297;
    border: none;
    border-radius: 6px;
    padding: 2vh 0;
    color: white;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 2vh;
    width: 100%;

}
button{
}
}


@media screen and (min-width: 768px) {
    margin: 0;
    margin-top: 1vh;

    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 1vw;


}
`